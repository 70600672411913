import { Component, OnDestroy, OnInit, Input, } from '@angular/core';
import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { Router } from '@angular/router';
import { TokenService } from '../../../shared/services/token.service';
import { MainUserIdService } from '../../../shared/services/mainUserId.service';


@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() position = 'normal';

  userName: string;
  userMenu = [{ title: 'Profile', link: '/profile', icon: 'person' }, { title: 'Log out', link: '/auth/logout', icon: 'log-out' }];
  logo = null;
  userRole: string | number;
  public profiles: Array<any>;
  public main_users;
  public mu_id$ = this.MainUserIdService.client_id;
  public wallet;
  public vrm = false;

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private router: Router,
    private tokenService: TokenService,
    private MainUserIdService: MainUserIdService,
  ) {
    this.userName = this.tokenService.name.getValue();
    this.userRole = this.tokenService.role.getValue()
  }

  ngOnInit() {
    if (this.userRole == (1 || 5)) { this.getUsersBasic() }
    else this.MainUserIdService.setMainUser()
  }

  getUsersBasic() {
    this.MainUserIdService.getMainUserAllowed();
    this.MainUserIdService.clients.subscribe(
      (res) => this.profiles = res.data)
  }

  changeMU(id) {
    this.MainUserIdService.setClientId(id);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    return false;
  }

  toggleSettings(): boolean {
    this.sidebarService.toggle(false, 'settings-sidebar');
    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  goToSearch() {
    this.router.navigate(['/search']);
  }

  ngOnDestroy() { }
}
