import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
      <b><a href="https://adiktive.com" target="_blank">Adiktive</a></b>
     </span>
  `,
})

export class FooterComponent { }