import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'currency' })
export class myCurrency implements PipeTransform {

  transform(input: string): string {
    let d = parseFloat(input).toFixed(2);
    return '$ ' + ( isNaN(+d) ? '0.00' : d );
  }
}
