import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'prettyNum' })
export class PrettyNum implements PipeTransform {

  transform(num: number): string | number {
    if (num) {
      return num.toLocaleString("en-US");
    } else {
      return num
    }
  }
}
