import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../api.service';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root'
})

export class MainUserIdService {
  public client_id = new BehaviorSubject<number>(this.tokenService.adk_mu.getValue());
  public clients = new Subject<any>();
  public clients_all: any;

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private tokenService: TokenService
  ) { }

  public setMainUser(): void{
    console.log(this.tokenService.adk_mu.getValue())
    if(this.tokenService.adk_mu.getValue()) {
      this.client_id.next(this.tokenService.adk_mu.getValue());
      // this.setClientId(+this.tokenService.adk_mu.getValue(), true);
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          adkmu: +this.tokenService.adk_mu.getValue()
        },
        queryParamsHandling: 'merge',
        skipLocationChange: false,
      });
    }
  }

  public getMainUserAllowed(): Observable<any> {
    this.apiService.mainUserForHeader().subscribe(
      (res: any) => {
        this.clients.next(res);

        if (this.client_id.getValue() == 0) {
          this.route.queryParams.subscribe(params => {
            // if (this.client_id.getValue() > 0 && this.client_id.getValue() == params['adkmu']) {
            //   console.log('a');
            // }
            // else 
            if (+params['adkmu'] > 0 && res.data.find(x => x.id == +params['adkmu'])) {
              console.log('yy: ' + params['adkmu']);
              this.setClientId(+params['adkmu'], false);
              // console.log('b');
            }
            else {
              this.setClientId(+this.tokenService.adk_mu.getValue(), false);
              // console.log('c');
            }
          });
        } else {
          // this.setClientId(+this.tokenService.adk_mu.getValue(), false);
        }

        return this.clients.asObservable();
      }
    )
    return this.clients.asObservable();
  }

  public setClientId(val: number, reload: boolean = true) {
    this.client_id.next(val);
    console.log(val)

    localStorage.setItem('adkmu', val.toString());
    // delete cached data
    localStorage.removeItem('dashboard');
    localStorage.removeItem('copy_campaign_path');

    // set or update url params
    this.router.navigate(['/dashboard'], {
      relativeTo: this.route,
      queryParams: { adkmu: val },
      replaceUrl: true,
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });

    if (reload) {
      setTimeout(() => { location.reload() }, 250);
    }
  }


  public getClientObservable(): Observable<number> {
    return this.client_id.asObservable();
  }

  public getClientId(): number {
    return this.client_id.getValue();
  }
}
