import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { tap } from 'rxjs/operators';
import { TokenService } from '../services/token.service';

/* 
***********
DESCRIPTION OF ROLES (-> means is active)
->Admin (admin - 1)
->SystemManager(sm - 2)
->Reseller (rs - 5)
->UserSmartLink (sl)
->UserDefault (UserDefault - 8)
  
EXTRA:
  Demo with block traffic (demo)
  For every account, you can have a super_user... so with 2 users, you can use 1 account...
***********
*/


/* 
***********
DESCRIPTION OF PERMISSIONS
-> ADK-Default (id-1)
-> ADK-Banners (id-2)
*/



// check is user IS AUTHENTICATED, so he can procees to pages folder
@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: NbAuthService, private router: Router) { }

  canActivate() {
    return this.authService.isAuthenticated()
      .pipe(
        tap(authenticated => {
          if (!authenticated) {
            this.router.navigate(['auth/login']);
          }
        }),
      );
  }
}

// roles: 1 - Admin
@Injectable()
export class AuthGuardAdmin implements CanActivate {

  constructor(private authService: NbAuthService, private router: Router, private tokenService: TokenService) { }

  canActivate() {
    return this.authService.isAuthenticated()
      .pipe(
        tap(authenticated => {
          if (!authenticated) {
            this.router.navigate(['auth/login']);
          } else {
            let role = this.tokenService.role.getValue();
            if (role != '1') {
              this.router.navigate(['/']);
            }
          }
        }))
  };
};
//roles: 2 - SystemManager
@Injectable()
export class AuthGuardSystemManager implements CanActivate {

  constructor(private authService: NbAuthService, private router: Router, private tokenService: TokenService) { }

  canActivate() {
    return this.authService.isAuthenticated()
      .pipe(
        tap(authenticated => {
          if (!authenticated) {
            this.router.navigate(['auth/login']);
          } else {
            let role = this.tokenService.role.getValue();
            if (role != '2') {
              this.router.navigate(['/']);
            }
          }
        }))
  };
};



// roles: 5 - RESELLER  
@Injectable()
export class AuthGuardReseller implements CanActivate {

  constructor(private authService: NbAuthService, private router: Router, private tokenService: TokenService) { }

  canActivate() {
    return this.authService.isAuthenticated()
      .pipe(
        tap(authenticated => {
          if (!authenticated) {
            this.router.navigate(['auth/login']);
          } else {
            let role = this.tokenService.role.getValue();
            if (role != '5') {
              this.router.navigate(['/']);
            }
          }
        }))
  }
};
