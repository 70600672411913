import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';


// **********************
// PRODUCTION OR DEV
const production = true; // <----------------------------

// API CONSTANTS
let api1 = 'http://localhost:8000/';
let api2 = 'https://api1.adiktive.com/';
let endpoint_stage = api1;
let rootUrl = production ? 'https://api1.adiktive.com/' : endpoint_stage;
let apiUrl = rootUrl;


// **********************

export const dev = {
	auth: rootUrl,
	production: production,
}

@Injectable({
	providedIn: 'root',
})

export class ApiService {
	baseUrl() {
		return rootUrl;
	}

	constructor(private http: HttpClient) {
		this.http = inject(HttpClient);
	}

	// AUTH
	changePassword(data) {
		return this.http.post(apiUrl + 'auth/password-change', data);
	}

	// CAMPAIGNS
	getCampaignsBasics() {
		return this.http.get(apiUrl + 'campaigns/basics')
	}
	getCampaignBasics() {
		return this.http.get(apiUrl + 'campaigns/campaign/basics')
	}
	getCampignsList(data) {
		return this.http.post(apiUrl + 'campaigns/filter', data);
	}
	getCampignsTotals(data) {
		return this.http.post(apiUrl + 'campaigns/totals', data);
	}
	getCampignById(id) {
		return this.http.get(apiUrl + `campaigns/campaign/get/${id}`)
	}
	updateCampaign(data) {
		return this.http.post(apiUrl + `campaigns/campaign/update`, data)
	}
	updateCampaignRating(camp_id, rating) {
		return this.http.get(apiUrl + `campaigns/campaign/update/rating/${camp_id}/${rating}`)
	}
	deleteCampaigns(data) {
		return this.http.post(apiUrl + `campaigns/delete`, data)
	}
	pauseCampaigns(data) {
		return this.http.post(apiUrl + `campaigns/pause`, data)
	}
	createCampaign(data) {
		return this.http.post(apiUrl + `campaigns/campaign/create`, data)
	}
	checkCampaignName(name) {
		return this.http.get(apiUrl + `campaigns/check/campaign_name/${name}`)
	}
	checkSubcampaignName(camp_id, name) {
		return this.http.get(apiUrl + `campaigns/check/subcampaign_name/${camp_id}/${name}`)
	}
	checkPathName(camp_id, sub_id, name) {
		return this.http.get(apiUrl + `campaigns/check/path_name/${camp_id}/${sub_id}/${name}`)
	}
	cacheCampaign(id) {
		return this.http.get(apiUrl + `campaigns/cache/create/${id}`)
	}
	getRulesCategories(id) {
		return this.http.get(apiUrl + `campaigns/rules/categories/${id}`)
	}
	// getCampaignBannersBasics() {
	// 	return this.http.get(apiUrl + `campaigns/banners/basics`)
	// }
	filterBannersBySize(data) {
		return this.http.post(apiUrl + `campaigns/banners/filter`, data)
	}
	// getCampaignLandersBasics() {
	// 	return this.http.get(apiUrl + `campaigns/landers/basics`)
	// }
	filterCampaignLanders(data) {
		return this.http.post(apiUrl + `campaigns/landers/filter`, data)
	}
	// getCampaignOffersBasics() {
	// 	return this.http.get(apiUrl + `campaigns/offers/basics`)
	// }
	filterCampaignOffers(data) {
		return this.http.post(apiUrl + `campaigns/offers/filter`, data)
	}
	checkRuleName(camp_id, sub_id, name) {
		return this.http.get(apiUrl + `campaigns/check/rule_name/${camp_id}/${sub_id}/${name}`)
	}
	checkAOSettings(data){
		return this.http.post(apiUrl + `campaigns/campaign/check_ao`, data)
	}
	runAOManually(camp_id, path_id){
		return this.http.get(apiUrl + `campaigns/campaign/run_ao/${camp_id}/${path_id}`)
	}
	addDynamicPathItems(camp_id, path_id){
		return this.http.get(apiUrl + `campaigns/campaign/add_dynamic_ao_items/${camp_id}/${path_id}`)
	}
	getPathAOGeneral(path_id: number){
		return this.http.get(apiUrl + `campaigns/campaign/path_ao_general/${path_id}`)
	}
	getPathAOChart(path_id: number){
		return this.http.get(apiUrl + `campaigns/campaign/path_ao_chart/${path_id}`)
	}
	getPathAOReport(path_id: number, skip_end: number, take: number){
		return this.http.get(apiUrl + `campaigns/campaign/path_ao_report/${path_id}/${skip_end}/${take}`)
	}


	// Banners
	getBannersBasic() {
		return this.http.get(apiUrl + 'banners/basics')
	}
	getBannersList(data) {
		return this.http.post(apiUrl + `banners/filter`, data)
	}
	getBannersTotals(data) {
		return this.http.post(apiUrl + 'banners/totals', data);
	}
	getBannerById(id) {
		return this.http.get(apiUrl + `banners/banner/get/${id}`)
	}
	createBanner(data) {
		return this.http.post(apiUrl + `banners/banner/create`, data)
	}
	updateBanner(data) {
		return this.http.post(apiUrl + `banners/banner/update`, data)
	}
	deleteBanners(data) {
		return this.http.post(apiUrl + `banners/delete`, data)
	}
	checkBannerName(name) {
		return this.http.get(apiUrl + `banners/check/lander_name/${name}`)
	}
	updateMultipleBanners(data) {
		return this.http.post(apiUrl + `banners/update/multiple`, data)
	}

	// Landers
	getLandersList(data) {
		return this.http.post(apiUrl + `landers/filter`, data)
	}
	getLandersBasic() {
		return this.http.get(apiUrl + `landers/basics`)
	}
	getLanderById(id) {
		return this.http.get(apiUrl + `landers/lander/get/${id}`)
	}
	createLander(data) {
		return this.http.post(apiUrl + `landers/lander/create`, data)
	}
	updateLander(data) {
		return this.http.post(apiUrl + `landers/lander/update`, data)
	}
	deleteLanders(data) {
		return this.http.post(apiUrl + `landers/delete`, data)
	}
	checkLanderName(name) {
		return this.http.get(apiUrl + `landers/check/lander_name/${name}`)
	}
	updateMultipleLanders(data) {
		return this.http.post(apiUrl + `landers/update/multiple`, data)
	}

	// Offers
	getOffersList(data) {
		return this.http.post(apiUrl + `offers/filter`, data)
	}
	getOffersBasic() {
		return this.http.get(apiUrl + `offers/basics`)
	}
	getOfferById(id) {
		return this.http.get(apiUrl + `offers/offer/get/${id}`)
	}
	checkOffersUpdate(ids: Array<number>) {
		return this.http.post(apiUrl + `offers/update/check`, ids)
	}
	deleteOffers(data) {
		return this.http.post(apiUrl + `offers/delete`, data)
	}
	checkOfferName(name) {
		return this.http.get(apiUrl + `offers/check/offer_name/${name}`)
	}
	createOffer(data) {
		return this.http.post(apiUrl + `offers/offer/create`, data)
	}
	updateOffer(data) {
		return this.http.post(apiUrl + `offers/offer/update`, data)
	}
	updateMultipleOffers(data) {
		return this.http.post(apiUrl + `offers/update/multiple`, data)
	}


	// Traffic Sources
	filterTrafficSources(data: any) {
		return this.http.post(apiUrl + `trafficsources/filter`, data)
	}
	getTrafficSource(id: number) {
		return this.http.get(apiUrl + `trafficsources/get/${id}`)
	}
	checkTrafficSourceName(name: string) {
		return this.http.get(apiUrl + `trafficsources/check/trafficsource_name/${name}`)
	}
	createTrafficSource(data: any) {
		return this.http.post(apiUrl + `trafficsources/trafficsource/create`, data)
	}
	updateTrafficSource(data: any) {
		return this.http.post(apiUrl + `trafficsources/trafficsource/update`, data)
	}
	deleteTrafficSource(data: any) {
		return this.http.post(apiUrl + `trafficsources/delete`, data)
	}
	getTrafficSourceTemplates() {
		return this.http.get(apiUrl + `trafficsources/templates/list`)
	}

	// Groups
	getGroups() {
		return this.http.get(apiUrl + `groups/get/list`)
	}
	deleteGroups(data) {
		return this.http.post(apiUrl + `groups/delete`, data)
	}
	checkGroupName(name) {
		return this.http.get(apiUrl + `groups/check/group_name/${name}`)
	}
	createGroup(data) {
		return this.http.post(apiUrl + `groups/group/create`, data)
	}
	updateGroup(data) {
		return this.http.post(apiUrl + `groups/group/update`, data)
	}

	// Tags
	getTagList() {
		return this.http.get(apiUrl + `tags/get/list`)
	}
	deleteTags(data) {
		return this.http.post(apiUrl + `tags/delete`, data)
	}
	checkTagName(name) {
		return this.http.get(apiUrl + `tags/check/tag_name/${name}`)
	}
	createTag(data) {
		return this.http.post(apiUrl + `tags/tag/create`, data)
	}
	updateTag(data) {
		return this.http.post(apiUrl + `tags/tag/update`, data)
	}

	// Offergroups
	getOffergroupBasic() {
		return this.http.get(apiUrl + `offergroups/basics`)
	}
	getOffergroupList(data) {
		return this.http.post(apiUrl + `offergroups/filter`, data)
	}
	getOfferGroupById(id) {
		return this.http.get(apiUrl + `offergroups/get/${id}`)
	}
	deleteOffergroups(data) {
		return this.http.post(apiUrl + `offergroups/delete`, data)
	}
	checkOffergroupName(name) {
		return this.http.get(apiUrl + `offergroups/check/offergroup_name`, { params: { name: name } })
	}
	createOffergroup(data) {
		return this.http.post(apiUrl + `offergroups/offergroup/create`, data)
	}
	updateOffergroup(data) {
		return this.http.post(apiUrl + `offergroups/offergroup/update`, data)
	}

	// Users
	getUsersBasic() {
		return this.http.get(apiUrl + `users/basics`)
	}
	getUsersList(data) {
		return this.http.post(apiUrl + `users/filter`, data)
	}
	getMainUsersList(data) {
		return this.http.post(apiUrl + `users/main_users/filter`, data)
	}
	deleteUser(data) {
		return this.http.post(apiUrl + `users/delete`, data)
	}
	deleteMainUser(data) {
		return this.http.post(apiUrl + `users/main_users/delete`, data)
	}
	checkUserName(name) {
		return this.http.get(apiUrl + `users/check/user_name/${name}`)
	}
	checkUserEmail(email) {
		return this.http.get(apiUrl + `users/check/user_email/${email}`)
	}
	createUser(data) {
		return this.http.post(apiUrl + `users/user/create`, data)
	}
	updateUser(data) {
		return this.http.post(apiUrl + `users/user/update`, data)
	}
	checkMainUserName(name) {
		return this.http.get(apiUrl + `users/check/main_user_name/${name}`)
	}
	createMainUser(data) {
		return this.http.post(apiUrl + `users/main_user/create`, data)
	}
	updateMainUser(data) {
		return this.http.post(apiUrl + `users/main_user/update`, data)
	}
	changeProfile() {
		return this.http.get(apiUrl + 'users/user/change_profile')
	}
	mainUserForHeader() {
		return this.http.get(apiUrl + 'profile/get/main_users_allowed')
	}

	// Resellers
	getResellersBasic() {
		return this.http.get(apiUrl + `resellers/basics`)
	}

	// Affiliate Networks
	// getAffNetList() {
	// 	return this.http.get(apiUrl + `affiliate_networks/get/list`)
	// }
	getAffNetList(data: any) {
		return this.http.post(apiUrl + `affiliate_networks/filter`, data)
	}
	deleteAffNet(data) {
		return this.http.post(apiUrl + `affiliate_networks/delete`, data)
	}
	checkAffNetName(name) {
		return this.http.get(apiUrl + `affiliate_networks/check/name/${name}`)
	}
	createAffNet(data) {
		return this.http.post(apiUrl + `affiliate_networks/create`, data)
	}
	updateAffNet(data) {
		return this.http.post(apiUrl + `affiliate_networks/update`, data)
	}

	// Dashboard
	getDashGeneral() {
		return this.http.post(apiUrl + `dashboard/general`, {})
	}
	getDashInfo(data) {
		return this.http.post(apiUrl + `dashboard/info`, data)
	}
	getDashChart(data) {
		return this.http.post(apiUrl + `dashboard/chart`, data)
	}

	// Stats-Report
	getReport(data) {
		return this.http.post(apiUrl + `stats/report`, data)
	}
	getStatsBasics(table, id) {
		return this.http.get(apiUrl + `stats/report/basics/${table}/${id}`)
	}
	getPresetsList(table: string) {
		return this.http.get(apiUrl + `stats/presets/list/${table}`)
	}
	createPreset(data) {
		return this.http.post(apiUrl + 'stats/presets/create', data)
	}
	deletePreset(id) {
		return this.http.get(apiUrl + `stats/presets/delete/${id}`)
	}
	exportReport(data) {
		return this.http.post(apiUrl + 'stats/report/export/csv', data)
	}

	// FallBacks
	getFallbacksList() {
		return this.http.get(apiUrl + `fallbacks/get/list`)
	}
	updateFallback(data) {
		return this.http.post(apiUrl + `fallbacks/fallback/update`, data)
	}
	deleteFallbacks(data) {
		return this.http.post(apiUrl + `fallbacks/delete`, data)
	}


	// LOGS
	getClickLogs(data: any) {
		return this.http.post(apiUrl + `logs/click/filter`, data)
	}
	getConversionLogs(data: any) {
		return this.http.post(apiUrl + `logs/conversion/filter`, data)
	}


	// ADK-BANNERS
	public getADKBannersBasics(){
		return this.http.get(apiUrl + `adk_banners/basics`)
	}
	public generateADKBanners(data){
		return this.http.post(apiUrl + `adk_banners/generate`, data)
	}
	public createADKBTemplate(data){
		return this.http.post(apiUrl + `adk_banners/template/create`, data)
	}
}