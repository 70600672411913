import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MainUserIdService } from '../services/mainUserId.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenService } from '../services/token.service';

@Injectable()
export class mainUserHttpInterceptor implements HttpInterceptor {

  constructor(private mainUserIdService: MainUserIdService, private route: ActivatedRoute, private router: Router,
    private tokenService: TokenService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any> | never> {

    let param_id = 0;
    let id: number;
    let role = this.tokenService.role.getValue()
    this.route.queryParams.subscribe(params => {
      if (params.adkmu) {
        this.mainUserIdService.client_id.next(params.adkmu);
        param_id = params.adkmu
        id = this.mainUserIdService.client_id.getValue()

      } else if (param_id == 0 && (role != ('1' || '5'))) {
        id = this.tokenService.adk_mu.getValue();
      } else if (param_id == 0 && (role == ('1' || '5'))) {
        if (this.mainUserIdService.client_id.getValue() == 0) {
          id = this.tokenService.adk_mu.getValue();
        } else {
          id = this.mainUserIdService.client_id.getValue()
        }
      } else {
        id = param_id ? param_id : this.mainUserIdService.client_id.getValue();
      }
    }
    );

    let copy_request: any
    if (id > 0) {
      copy_request = request.clone({
        setHeaders: {
          adk_mu: id.toString()
        }
      });
    } else {
      copy_request = request.clone();
    }

    return next.handle(copy_request).pipe(
      catchError((error) => {
        return this.errorHandler(request, error);
      }));
  }

  errorHandler(request: HttpRequest<any>, error: any): Observable<never> {
    if (error instanceof HttpErrorResponse
    ) {

    }
    return throwError(() => error);
  }

}
