import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class TokenService {
  public tokenData = JSON.parse(localStorage.getItem('auth_app_token') || 'null');
  public isAuthenticated = new BehaviorSubject<boolean | null>(null);

  public name = new BehaviorSubject<string | null>(null);
  public role = new BehaviorSubject<string | null>(null);
  public permissions = new BehaviorSubject<Array<number> | null>(null);
  public adk_mu = new BehaviorSubject<number | null>(0);
  private tokenExpirationTimer: any;

  constructor(
    private nbService: NbAuthService,
    private router: Router,
    private location: Location
  ) { }

  public getToken(): void {
    this.nbService.onTokenChange().subscribe(token => {
      this.nbService.isAuthenticated().subscribe(auth => {
        if (this.location.path().includes('/auth/reset-password?reset_password_token=')) return;
        else if (!token || !token.isValid()) this.onLogout();
        else {
          let payload = token.getPayload();
          this.setPayload(payload);
          this.checkTokenExpiration(token['createdAt']);
        }
      })
    });
  };

  private setPayload(payload: any | null) {
    this.name.next(payload ? payload['name'] : null);
    this.role.next(payload ? payload['role_id'] : null);
    this.permissions.next(payload ? payload['permissions'] : null);
    this.adk_mu.next(payload ? payload['main_user_id'] : 0);
  }

  private checkTokenExpiration(created: number) {
    let now = new Date().getTime();
    let createdAt = new Date(created).getTime();
    let minutes = 60 * 1000;

    let expiresAt = createdAt + (60 * minutes);
    let expiresIn = expiresAt - now;
    let expired = expiresIn < 0;
    let refreshIn = expiresIn - (10 * minutes);

    if (expired) this.onLogout();
    else if (expiresIn < 10 * minutes) this.refreshToken();
    else this.setTimeout(refreshIn);
  }

  private setTimeout(ms: number): void {
    this.clearTimeout();
    this.tokenExpirationTimer = setTimeout(() => this.refreshToken(), ms);
  }

  private clearTimeout(): void {
    if (this.tokenExpirationTimer) clearTimeout(this.tokenExpirationTimer);
    this.tokenExpirationTimer = null;
  }

  public refreshToken(): void {
    this.clearTimeout();

    this.nbService.isAuthenticated().subscribe(
      (auth) => {
        if (auth) {
          this.nbService.refreshToken('email').subscribe(
            () => { },
            () => this.onLogout())
        } else this.onLogout();
      },
      () => this.onLogout());
  }

  public onLogout(): void {
    this.setPayload(null);
    this.clearTimeout();
    this.adk_mu.next(0);
    localStorage.removeItem('copy_campaign_path');
    localStorage.removeItem('dashboard');
    this.router.navigate(['/auth/logout'],
      {
        queryParams: {
          adkmu: null,
        },
        queryParamsHandling: 'merge',
      });
  }

}